<template>
  <div>
    <upsert-monday-template
      :is-upsert-template-open.sync="isUpsertTemplateOpen"
      :upsert-data.sync="upsertTemplateData"
      @refresh="refetchData"
    />

    <!-- Filters -->
    <monday-template-list-filters
      v-if="false"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :create-at-start-filter.sync="createAtStartFilter"
      :create-at-end-filter.sync="createAtEndFilter"
    />

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            </div>
          </b-col>

          <b-col v-if="canCreate" cols="12" md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" class="w-100" @click="startTemplateCreate"> Create Template </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTemplateListTable"
        :items="fetchTemplates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!--        selectable-->
        <!--        select-mode="range"-->
        <!--        @row-selected="onRowSelected"-->

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link class="font-weight-bold">
            {{ data.value }}
          </b-link>
        </template>

        <!-- Column: Created At -->
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ moment(data.value).format('L') }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(type)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(label)="data">
          <b-badge :variant="`light-secondary`" class="w-100">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(module)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(type)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="startTemplateEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="startTemplateRemove(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remove</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTemplates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import store from '@/store';
import useTemplateList from './useMondayTemplateList';
import MondayTemplateListFilters from './MondayTemplateListFilters';
import moment from 'moment';

import placementStoreModule from '../placementStoreModule';
import UpsertMondayTemplate from '@/views/apps/placement/monday-template-list/components/UpsertMondayTemplate';

export default {
  components: {
    UpsertMondayTemplate,
    MondayTemplateListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  methods: {
    startTemplateCreate() {
      this.upsertTemplateData = { isNew: true, type: 'email', enabled: true, partial: false };
      this.isUpsertTemplateOpen = true;
    },
    startTemplateEdit(template) {
      this.upsertTemplateData = { ...template, isNew: false };
      this.isUpsertTemplateOpen = true;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    async selectNextPage(page, payload) {
      return new Promise((resolve, reject) => {
        store
          .dispatch('app-placement/fetchTemplates', { ...payload, page: page })
          .then(resolve)
          .catch(reject);
      });
    },
  },
  data: () => {
    return {
      selected: [],
      processing: false,
      vm: undefined,
      isUpsertTemplateOpen: false,
      upsertTemplateData: undefined,
    };
  },
  computed: {
    canCreate() {
      return this.$can('integrations.monday.template.create');
    },
  },
  mounted() {
    this.vm = this;
  },
  setup() {
    const PLACEMENT_STORE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PLACEMENT_STORE_NAME)) store.registerModule(PLACEMENT_STORE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLACEMENT_STORE_NAME)) store.unregisterModule(PLACEMENT_STORE_NAME);
    });

    const {
      statusOptions,
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,

      createAtStartFilter,
      createAtEndFilter,

      statusFilter,

      refetchData,
    } = useTemplateList();

    return {
      fetchTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,

      createAtStartFilter,
      createAtEndFilter,

      statusFilter,
      statusOptions,

      refetchData,

      avatarText,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.Template-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
